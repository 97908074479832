<template>
	<div>

        <s-select-definition hidden @change="changeLayette($event)" label="Nro Canastilla" :def="1259"  /> 
		<v-container>
			<v-data-table
                dense
                :items="itemsLayette"
                :headers="headers"
            >

            <template v-slot:item.Positions="{ item }">
					<v-btn
						color="success"
						x-small
						rounded
						@click="clickPositions(item)"
					>
						+</v-btn
					>
				</template>

            </v-data-table>

			<v-dialog
				v-model="openModal"
				v-if="openModal"
				width="700"
				persistent
			>
				<v-card>
					<v-container>
						<v-row>
							<h3 class="mt-3 ml-6">
								Posiciones - {{ itemSelected.LypDescription }}-
							</h3>
							<v-spacer> </v-spacer>
							<v-btn
								@click="openModal = false"
								class="mt-3 mr-3"
								small
								fab
								color="error"
								><i
									style="font-size: 16px"
									class="fas fa-times"
								></i
							></v-btn>
						</v-row>

						<v-row>
							<v-col class="pb-0">
								<v-divider></v-divider>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								<s-crud
									:filter="filterLayettePos"
									:config="configLayettePos"
									add
									edit
									remove
									@save="savePosition($event)"
									noFooter
									noSearch
								>
									<template scope="props">
										<v-row style="margin:auto">
											<v-col lg="8" cols="12" class="pt-0">
												<s-text
													v-model="
														props.item.LypDescription
													"
													label="Descripción"
												></s-text>
											</v-col>
											<v-col lg="4" cols="12" class="pt-0">
												<s-text
													v-model="
														props.item.LypPosition
													"
													number
													:min="0"
													label="Posición"
												></s-text>
											</v-col>
										</v-row>
									</template>
								</s-crud>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-dialog>
		</v-container>
	</div>
</template>

<script>
	import _sLayettePositionsService from "../../../services/FreshProduction/PrfLayettePositionsService";
	import _sPrfChamberPositionService from "@/services/FreshProduction/PrfChamberPositionService";

	export default {
		data() {
			return {
				filterLayettePos: {},
				configLayettePos: {
					model: {
						LypID: "ID",
						Positions: "",
					},
					service: _sLayettePositionsService,
					headers: [
						{ text: "Descripción", value: "LypDescription" },
						{ text: "Posicion", value: "LypPosition" },
					],
				},

                headers: [
						{ text: "Posiciones", value: "Positions", width: 100 },
						{ text: "ID", value: "DedID" , width: 100 },
						{ text: "Canastilla", value: "DedDescription" },
					],

				openModal: false,
                itemsLayette: [],
			};
		},

		created() {
		},

		methods: {

            changeLayette(items){
                this.itemsLayette =items;
                console.log(this.itemsLayette);
            },

			savePosition(val) {
				
				if(val.LypDescription.length == 0){
					this.$fun.alert("Digite una Descripción", "warning")
					return;
				}

				if(val.LypPosition <= 0){
					this.$fun.alert("Digite una Posición", "warning")
					return;
				}
				val.LypDescription = val.LypDescription.trim();
				val.TypeLayetteID  = this.itemSelected.DedID
                val.TypeLayette  = this.itemSelected.DedValue
				val.SecStatus = 1
				val.UsrCreateID = this.$fun.getUserID()

				console.log("Guardar posiciones", val);
				val.save()
			},

			clickPositions(item) {
				console.log(item);
				this.itemSelected = item;
                this.filterLayettePos.TypeLayetteID = item.DedID
				this.openModal = true;
			},
		},
	};
</script>