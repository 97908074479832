import Service from "../Service";
import Vue from "vue";
const resource = "Layette/"

export default {
    save(obj, requestID) {
        return Service.post(resource + "savePosition", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(obj, requestID) {
        return Service.post(resource + "paginationPosition", obj, {
            params: { requestID: requestID }
        });
    },

};